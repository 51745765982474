<template>
  <div class="fullwidth tags-scoring pa-3">
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <o-table
        class="table__overflow-auto main-table-wrap"
        :class="{ 'o-table-has-pagination': localData.length && totalPages > 1 }"
        :data="localData"
        hoverable
        :loading="loading"
        :mobile-cards="false"
      >
        <o-table-column
          v-slot="props"
          field="riskScore"
          label="Score"
          sortable
        >
          <div class="score-mark">
            <div
              v-if="props.row.riskScore === null"
              class="dark-color"
            >
              --
            </div>
            <GlScoreLevelChip
              v-else-if="!loading"
              :level="props.row.riskScore ? props.row.riskScore.toFixed(0) : 0"
              score
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="address"
          label="Address"
          sortable
        >
          <div
            class="link"
            @click="openInNewTab(props.row.address.address ? props.row.address.address : props.row.address)"
          >
            {{ props.row.address.address ? props.row.address.address : props.row.address }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="timestamp"
          label="Date & Time"
          sortable
        >
          <div class="min-w-150">
            {{ formatDate(props.row.timestamp, 'dd.MM.yyyy, HH:mm') }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="token_ticker"
          label="Token"
          sortable
          :visible="$can('use', 'eth')"
        >
          {{ props.row.tokenTicker || '--' }}
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center m-column pa-3 m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 m-fs-12 bold m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="localData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//Vuex
import { mapActions, mapState } from "vuex";
// Components
import vSelect from 'vue-select'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
import GlMenuItem from '@/components/gl-menu-item'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
// Utils
import { formatDate } from "@/utils/format-date";

export default {
  name: 'TagsScoring',
  components: {
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    GlScoreLevelChip,
  },
  data() {
    return {
      total: 1,
      perPage: 10,
      localData: [],
      totalPages: 1,
      loading: false,
      currentPage: 1,
      pagesOptions: [5, 10,20, 50, 100],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType'])
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
    },
  },
  created() {
    this.perPage = localStorage.getItem('address-api-call-per-page') || this.perPage
    localStorage.setItem('address-api-call-per-page', this.perPage)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    ...mapActions({
      getRiskAddressHistoryList: 'analytics/getRiskAddressHistoryList',
    }),
    async loadData() {
      this.loading = true
      const sendParams = this.formattedSendData()
      await this.getRiskAddressHistoryList(sendParams).then(( { data: { items, totalItems } }  ) => {
        this.localData = items
        this.total = totalItems
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.checkedRows = []
      }).finally(() => {
        this.loading = false
      })
    },
    openInNewTab(address) {
      const { href } = this.$router.resolve({ name: 'report', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    formattedSendData() {
      return {
        count: this.perPage,
        search: this.search,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    countChange() {
      localStorage.setItem('address-api-call-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>
.score-mark {
  display: inline-block;
  border-radius: 40px;
  color: #fff;
}

.tags-scoring {
  height: fit-content;
  background: #fff;
}

.score-clock-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.tags-scoring {
  border-radius: 3px;
}
</style>
